import * as Sentry from '@sentry/vue';
import { BootstrapVue } from 'bootstrap-vue';
import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';
import VueToasted from 'vue-toasted';
import VueApexCharts from 'vue-apexcharts';
import VueNativeSocket from 'vue-native-websocket';

import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import filters from './filters';
import { Auth0Plugin } from './auth';
import Permissions from './plugins/permissions';
import Mixpanel from './plugins/mixpanel';

import en from './lang/en';
import fr from './lang/fr';
import './assets/_scss/main.scss';

if (process.env.VUE_APP_LOCAL !== 'true') {
  Sentry.init({
    Vue,
    enabled: true,
    dsn: 'https://9fdd931f545e4707b3c158397b7681c9@o4504219356037120.ingest.sentry.io/4504219357020160',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false
      }),
      Sentry.httpClientIntegration()
    ],
    sendDefaultPii: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/[a-z.]*app\.etainabl\.com/, /^https:\/\/[a-z-]*api\.etainabl\.com/, /^\//],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
    release: 'etainabl-ui@' + process.env.VUE_APP_VERSION,
    environment: process.env.VUE_APP_ENV === 'dev' ? 'development' : 'production',
    beforeSend(event, hint) {
      const error = hint.originalException;

      // Ignore errors from loading chunks
      if (error && error.message && error.message.match(/loading chunk \S* failed/i)) {
        return null;
      }

      return event;
    }
  });
}

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});
Vue.use(Permissions);

Vue.use(Mixpanel, {
  token: process.env.VUE_APP_MIXPANEL_TOKEN,
  debug: false,
  track_pageview: 'url-with-path-and-query-string',
  ignore_dnt: true
});

Vue.use(VueApexCharts);

Vue.use(VueResource);
Vue.use(Vuex);
Vue.use(VueToasted, {
  position: 'bottom-right',
  duration: 3000
});
Vue.use(BootstrapVue);

// i18n - START
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages: { en, fr } // set locale messages
});
// i18n - END

// Web Sockets - START
Vue.use(VueNativeSocket, process.env.VUE_APP_SOCKETS_API_URL, {
  connectManually: true,
  format: 'json',
  store: store._modules.root._children.socket.context
});

// Apex Charts - END

// Apex Charts - START
Vue.use(VueApexCharts);

window.Apex.chart = {
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
};

window.Apex.legend = {
  fontWeight: 600,
  labels: {
    colors: '#6c757d'
  }
};
// Apex Charts - END

Vue.component('apexchart', VueApexCharts);

Vue.config.productionTip = false;

filters(Vue);

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

if (window.Cypress) {
  window.__app__ = app;
}
