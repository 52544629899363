<template>
  <div data-cy="asset-discussion-page">
    <div class="row" data-cy="messages">
      <div class="col-xl-3 col-lg-4 col-md-5 col-12">
        <h4 class="mb-4">Messages</h4>
        <button
          v-if="$permissions.write('asset', parent)"
          data-cy="new-message-btn"
          class="btn btn-primary btn-block mb-4"
          @click="showNewMessage = true"
        >
          New Message
        </button>
        <div class="form-group">
          <div class="input-group">
            <input v-model="search" type="text" class="form-control border-0" placeholder="Search Messages.." />
            <div class="input-group-append">
              <span class="input-group-text border-0 bg-white">
                <i class="fa fa-fw fa-search"></i>
              </span>
            </div>
          </div>
        </div>

        <div v-if="filteredMessages.length > 0">
          <div>
            <p class="mb-2 text-muted"><i class="fa fa-thumbtack mr-2"></i><span class="font-w600">Pinned Messages</span></p>
          </div>
          <MessagesInbox
            :messages="filteredMessages.filter(m => m.pinned)"
            :selected-message="selectedMessage"
            :get-user-from-sub="getUserFromSub"
            class="mb-3"
            pinned
            @click-message="onClickMessage"
          />
          <MessagesInbox
            :messages="filteredMessages.filter(m => !m.pinned)"
            :selected-message="selectedMessage"
            :get-user-from-sub="getUserFromSub"
            @click-message="onClickMessage"
          />
        </div>
        <div v-else>
          <div class="alert alert-warning">No messages</div>
        </div>
      </div>

      <!-- new message -->
      <div class="col-xl-9 col-lg-8 col-md-7">
        <Spinner v-if="loading" />
        <div v-else-if="showNewMessage" data-cy="new-message">
          <div class="block block-rounded">
            <div class="block-content block-content-sm block-content-full bg-body-light">
              <div class="media py-3">
                <div class="media-body">
                  <div class="row justify-content-between">
                    <div class="col-sm-7">
                      <span class="font-w600">Create a new message</span>
                    </div>
                    <div class="col-sm-1">
                      <button class="btn btn-link text-danger fa-lg p-0" @click.prevent="showNewMessage = false">
                        <i class="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block block-rounded">
              <div class="block-content block-content-full">
                <FormGroup id="title" v-model="newMessage.title" label="Title" type="text" :error="validationErrors.title" />
                <FormGroup id="content" v-model="newMessage.content" label="Message" type="textarea" :error="validationErrors.content" />
                <FormGroup
                  id="category"
                  v-model="newMessage.category"
                  label="Category"
                  :type="companyCategories.length > 0 ? 'select' : 'text'"
                  :options="companyCategories"
                />
                <FormGroup
                  id="type"
                  v-model="newMessage.type"
                  label="Type"
                  :type="messageTypes.length > 0 ? 'select' : 'text'"
                  :options="messageTypes"
                />
                <FormGroup v-if="newMessage.type === 'action'" id="dueDate" v-model="newMessage.dueDate" label="Due Date" type="dateTimePicker" />
                <FormGroup
                  v-if="accounts.length > 0"
                  id="accountIds"
                  v-model="newMessage.accountIds"
                  label="Accounts"
                  type="select-array-list"
                  :options="accountOptions"
                  overflow
                />
                <div class="form-group">
                  <label class="d-block"> Attachments</label>
                  <FileUpload
                    v-model="newAttachments"
                    :loading="loadingAction.upload"
                    :file-types="[]"
                    :multiple-files="true"
                    @reset="newAttachments = null"
                  />
                </div>
                <button type="button" class="btn btn-primary mt-2" @click.prevent="onClickCreate">
                  <span v-if="loadingAction.upload || loadingAction.create"><i class="fa fa-spin fa-spinner mr-1"></i> Creating...</span>
                  <span v-else><i class="fa fa-check-circle mr-1"></i> Create Message</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="selectedMessage._id && selectedMessage.replies.length > 0" class="block block-rounded block-content">
          <div class="row align-items-center mb-3">
            <div class="col-md-6">
              <h3 class="mb-3">
                <span class="mr-3">{{ selectedMessage.title }}</span>
                <span class="badge badge-info text-capitalize mr-3">{{ selectedMessage.type }}</span>
                <span class="badge badge-success text-capitalize mr-3">{{ selectedMessage.category }}</span>
                <span v-if="selectedMessage.dueDate" class="badge badge-danger mr-3"
                  ><i class="fa fa-clock"></i> {{ selectedMessage.dueDate | date('DD/MM/YYYY HH:mm') }}</span
                >
                <span v-if="selectedMessage.status !== 'open'" class="badge badge-danger text-capitalize mr-3">CLOSED</span>
              </h3>
            </div>
            <div class="col-md-6">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <span> </span>
                <span>
                  <button
                    v-if="$permissions.write('asset', parent)"
                    data-cy="message-edit-btn"
                    type="button"
                    class="btn mr-3"
                    :class="{
                      'btn-alt-warning': selectedMessage.pinned,
                      'btn-alt-light': !selectedMessage.pinned
                    }"
                    @click.prevent="onClickPinMessage"
                  >
                    <i class="fa fa-thumb-tack mr-1"></i> {{ selectedMessage.pinned ? 'UnPin' : 'Pin' }}
                  </button>
                  <button
                    v-if="$permissions.write('asset', parent)"
                    data-cy="message-edit-btn"
                    type="button"
                    class="btn btn-alt-light mr-3"
                    @click.prevent="onClickEditMessage"
                  >
                    <i class="fa fa-pencil mr-1"></i> Edit
                  </button>
                  <button
                    v-if="$permissions.write('asset', parent)"
                    type="button"
                    class="btn btn-alt-light mr-3"
                    @click.prevent="onClickCloseMessage(selectedMessage)"
                  >
                    <i
                      class="fa mr-1"
                      :class="{ 'fa-eye-slash': selectedMessage.status === 'open', 'fa-eye': selectedMessage.status !== 'open' }"
                    ></i>
                    {{ selectedMessage.status === 'open' ? 'Close' : 'Open' }}
                  </button>
                  <button
                    v-if="$permissions.write('asset', parent)"
                    type="button"
                    class="btn btn-alt-danger"
                    @click.prevent="onClickDeleteMessage(selectedMessage)"
                  >
                    <i class="fa fa-trash mr-1"></i> Delete
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="row align-items-start border-bottom pb-3 mb-4">
            <div class="col-md-6">
              <div class="mb-3">
                <div class="mb-3">
                  <div v-if="reassignUser && $permissions.write('asset', parent)" class="d-flex align-items-center">
                    <FormGroup
                      v-if="$refs.editMessage"
                      id="assignedUserSub"
                      :value="selectedMessage.assignedUserSub"
                      label="Assigned To"
                      placeholder="Assign to user..."
                      type="select2"
                      :config="{ allowClear: true }"
                      :disabled="loadingAction.assignUser"
                      :options="companyUsers"
                      class="flex-grow-1"
                      @input="onChangeAssignedUser"
                    />
                    <div v-if="loadingAction.assignUser" class="ml-2">
                      <i class="fa fa-spin fa-spinner"></i>
                    </div>
                  </div>
                  <div v-else-if="selectedMessage.assignedUserSub" class="d-flex align-items-center">
                    <strong>Assigned to: </strong>
                    <span class="text-secondary ml-2">{{ getUserFromSub(selectedMessage.assignedUserSub).name }}</span>
                    <a
                      v-if="$permissions.write('asset', parent)"
                      href="#"
                      class="btn btn-sm btn-primary ml-2"
                      :class="{ disabled: loadingAction.assignUser }"
                      @click.prevent="reassignUser = true"
                      >Change</a
                    >
                    <a
                      v-if="$permissions.write('asset', parent)"
                      href="#"
                      class="btn btn-sm btn-alt-warning ml-2"
                      :class="{ disabled: loadingAction.assignUser }"
                      @click.prevent="onChangeAssignedUser('')"
                    >
                      <span v-if="loadingAction.assignUser"> <i class="fa fa-spin fa-spinner mr-2"></i>Unassigning...</span>
                      <span v-else>Unassign</span>
                    </a>
                  </div>
                  <div v-else-if="$permissions.write('asset', parent)">
                    <a href="#" class="btn btn-alt-warning" @click.prevent="reassignUser = true">Assign to user</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div v-if="selectedMessage.accounts">
                <ul class="list-group list-group-flush text-right accounts-list">
                  <span class="badge badge-primary mr-2">Linked Accounts</span>
                  <li v-for="account in selectedMessage.accounts.data" :key="account._id" class="list-group-item">
                    <router-link
                      class="font-w600"
                      :to="{ name: 'asset-accounts-overview', params: { id: account.assetId, accountId: account._id } }"
                      target="_blank"
                    >
                      {{ account.type | capitalize }} - {{ account.name }} [MPAN: {{ account.meterPointNumber }}]
                    </router-link>
                  </li>
                  <li v-if="selectedMessage.invoice" class="list-group-item">
                    <router-link
                      class="font-w600"
                      :to="{ name: 'asset-accounts-overview', params: { id: this.$route.params.id, accountId: invoice.accountId } }"
                      target="_blank"
                    >
                      <span class="badge badge-primary mr-2">Invoice</span>
                      {{ selectedMessage.invoice.values.invoiceNumber }} ({{ selectedMessage.invoice.values.startDate | date }} -
                      {{ selectedMessage.invoice.values.endDate | date }})
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="pinnedReplies.length !== 0" class="border-left border-right border-top mb-4 bg-light">
            <div class="mb-0 ml-4 mt-3">
              <i class="fa fa-thumb-tack mr-1 text-success fa-xl" />

              <small class="text-uppercase"> pinned replies </small>
            </div>

            <div v-for="(reply, replyIdx) in pinnedReplies" :key="reply._id" :class="{ 'border-bottom': replyIdx + 1 !== pinnedReplies.length }">
              <div :class="{ 'opacity-50': reply.deletedOn }">
                <div class="block-content block-content-sm block-content-full">
                  <div class="row align-items-start">
                    <div class="col-sm-7 d-flex align-items-center">
                      <img
                        v-if="getUserFromSub(reply.userSub).picture"
                        class="img-avatar img-avatar32 mr-4"
                        :src="getUserFromSub(reply.userSub).picture"
                        alt=""
                      />
                      <div>
                        <p class="message-title font-size-h5 font-w700 mb-0">{{ getUserFromSub(reply.userSub).name }}</p>
                        <p class="message-user font-size-sm text-secondary mb-0">{{ getUserFromSub(reply.userSub).email }}</p>
                      </div>
                    </div>
                    <div class="col-sm-5 d-flex justify-content-end">
                      <div class="text-right">
                        <p class="font-size-sm font-w500 text-muted mb-1">{{ reply.createdAt | date('dddd Do MMM YYYY HH:mm') }}</p>
                        <div v-if="!reply.deletedOn && $permissions.write('asset', parent)" class="d-flex justify-content-end">
                          <div class="d-flex justify-content-end">
                            <button class="btn btn-text btn-sm" @click.prevent="onClickPin(reply)">
                              <i class="fa fa-thumb-tack mr-1"></i> Unpin
                            </button>
                          </div>
                          <div v-if="reply.userSub === $auth.user.sub">
                            <button
                              v-if="!reply.isPinnedEdit"
                              class="btn btn-text btn-sm text-info"
                              @click.prevent="onClickEditReply(reply, 'isPinnedEdit')"
                            >
                              <i class="fa fa-pencil mr-1"></i> Edit
                            </button>

                            <button
                              v-if="reply.isPinnedEdit"
                              class="btn btn-alt-success btn-sm"
                              :disabled="reply.isSaving || reply.isDeleting"
                              @click.prevent="onClickSaveReply(reply, 'isPinnedEdit')"
                            >
                              <span v-if="reply.isSaving"><i class="fa fa-spinner fa-spin mr-1"></i> Saving...</span>
                              <span v-else><i class="fa fa-check mr-1"></i> Save</span>
                            </button>

                            <button class="btn btn-text btn-sm text-danger" @click.prevent="onClickDeleteReply(reply)">
                              <span v-if="reply.isDeleting"><i class="fa fa-spinner fa-spin mr-1"></i> Deleting...</span>
                              <span v-else><i class="fa fa-trash mr-1"></i> Delete</span>
                            </button>
                          </div>
                        </div>
                        <div v-else-if="reply.deletedOn" class="text-muted font-italic" :title="`Deleted on ${reply.deletedOn}`">
                          Reply deleted by user
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="block-content message-content">
                  <FormGroup v-if="reply.isPinnedEdit" :id="`reply-${reply._id}`" v-model="reply.content" type="textarea" label="" />
                  <p v-else-if="!reply.deletedOn" class="mb-4">{{ reply.content }}</p>
                </div>
                <div v-if="reply.attachments.length > 0 && !reply.deletedOn" class="block-content block-content-full bg-body-light">
                  <p class="text-muted text-uppercase mb-2">Attachments</p>
                  <div v-for="attachment in reply.attachments" :key="attachment._id">
                    <a href="#" class="font-size-sm text-muted pt-2" @click.prevent="onClickDownload(reply, attachment)"
                      ><i class="fa fa-paperclip"></i> {{ attachment.name }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(reply, replyIdx) in selectedMessage.replies"
            :key="reply._id"
            class="block block-rounded"
            :class="{ 'border-bottom': replyIdx + 1 !== selectedMessage.replies.length }"
          >
            <div :class="{ 'opacity-50': reply.deletedOn }">
              <div class="block-content block-content-sm block-content-full">
                <div class="row align-items-start">
                  <div class="col-sm-7 d-flex align-items-center">
                    <img
                      v-if="getUserFromSub(reply.userSub).picture"
                      class="img-avatar img-avatar32 mr-4"
                      :src="getUserFromSub(reply.userSub).picture"
                      alt=""
                    />
                    <div>
                      <p class="message-title font-size-h5 font-w700 mb-0">{{ getUserFromSub(reply.userSub).name }}</p>
                      <p class="message-user font-size-sm text-secondary mb-0">{{ getUserFromSub(reply.userSub).email }}</p>
                    </div>
                  </div>
                  <div class="col-sm-5 d-flex justify-content-end">
                    <div class="text-right">
                      <p class="font-size-sm font-w500 text-muted mb-1">{{ reply.createdAt | date('dddd Do MMM YYYY HH:mm') }}</p>
                      <div v-if="!reply.deletedOn && $permissions.write('asset', parent)" class="d-flex justify-content-end">
                        <button v-if="!reply.isEdit && !reply.pinned" class="btn btn-text btn-sm" @click.prevent="onClickPin(reply)">
                          <i class="fa fa-thumb-tack mr-1"></i> Pin
                        </button>
                        <div v-if="reply.userSub === $auth.user.sub">
                          <button v-if="!reply.isEdit" class="btn btn-text btn-sm text-info" @click.prevent="onClickEditReply(reply, 'isEdit')">
                            <i class="fa fa-pencil mr-1"></i> Edit
                          </button>
                          <button
                            v-if="reply.isEdit"
                            class="btn btn-alt-success btn-sm"
                            :disabled="reply.isSaving || reply.isDeleting"
                            @click.prevent="onClickSaveReply(reply, 'isEdit')"
                          >
                            <span v-if="reply.isSaving"><i class="fa fa-spinner fa-spin mr-1"></i> Saving...</span>
                            <span v-else><i class="fa fa-check mr-1"></i> Save</span>
                          </button>
                          <button class="btn btn-text btn-sm text-danger" @click.prevent="onClickDeleteReply(reply)">
                            <span v-if="reply.isDeleting"><i class="fa fa-spinner fa-spin mr-1"></i> Deleting...</span>
                            <span v-else><i class="fa fa-trash mr-1"></i> Delete</span>
                          </button>
                        </div>
                      </div>

                      <div v-else-if="reply.deletedOn" class="text-muted font-italic" :title="`Deleted on ${reply.deletedOn}`">
                        Reply deleted by user
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="block-content message-content">
                <FormGroup v-if="reply.isEdit" :id="`reply-${reply._id}`" v-model="reply.content" type="textarea" label="" />
                <p v-else-if="!reply.deletedOn" class="mb-4">{{ reply.content }}</p>
              </div>
              <div v-if="reply.attachments.length > 0 && !reply.deletedOn" class="block-content block-content-full bg-body-light">
                <p class="text-muted text-uppercase mb-2">Attachments</p>
                <div v-for="attachment in reply.attachments" :key="attachment._id">
                  <a href="#" class="font-size-sm text-muted pt-2" @click.prevent="onClickDownload(reply, attachment)"
                    ><i class="fa fa-paperclip"></i> {{ attachment.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-if="$permissions.write('asset', parent)">
            <div v-if="selectedMessage.status !== 'open'" class="alert alert-warning font-w600 text-center font-size-h5 mb-3">
              This message is closed. Sending a reply will re-open the message.
            </div>
            <FormGroup id="content" v-model="newMessage.content" type="textarea" placeholder="Enter a message..." />

            <div class="row align-items-center">
              <div class="col-md-9">
                <div class="form-group">
                  <label class="d-block"> Attachments</label>
                  <FileUpload
                    v-model="newAttachments"
                    :loading="loadingAction.upload"
                    :file-types="[]"
                    :multiple-files="true"
                    @reset="newAttachments = null"
                  />
                </div>
              </div>

              <div class="col-md-3 text-right">
                <button type="button" class="btn btn-primary mt-2" @click.prevent="onClickReply">
                  <span v-if="loadingAction.upload || loadingAction.reply"><i class="fa fa-spin fa-spinner mr-1"></i> Sending...</span>
                  <span v-else><i class="fa fa-check-circle mr-1"></i> Send Message</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      :open="!!modals.remove"
      title="Delete Message"
      :text="`Please confirm you would like to remove message: <strong>${modals.remove.title}</strong>`"
      @close="modals.remove = false"
      @submit="onRemove"
    />
    <ConfirmModal
      id="edit-message"
      ref="editMessage"
      data-cy="message-edit"
      :open="!!modals.edit"
      title="Edit Message"
      :text="`Please confirm you would like to edit message: <strong>${modals.edit.title}</strong>`"
      @close="modals.edit = false"
      @submit="onEdit"
    >
      <div v-if="!!modals.edit">
        <FormGroup id="title" v-model="modals.edit.title" label="Title" type="text" />
        <FormGroup id="type" v-model="modals.edit.type" label="Type" :type="messageTypes.length > 0 ? 'select' : 'text'" :options="messageTypes" />
        <FormGroup v-if="modals.edit.type === 'action'" id="dueDate" v-model="modals.edit.dueDate" label="Due Date" type="dateTimePicker" />
        <FormGroup
          id="category"
          v-model="modals.edit.category"
          label="Category"
          :type="companyCategories.length > 0 ? 'select' : 'text'"
          :options="companyCategories"
        />
        <FormGroup
          v-if="accounts.length > 0"
          id="accountIds"
          v-model="modals.edit.accountIds"
          label="Accounts"
          type="select-array-list"
          :options="accountOptions"
          overflow
        />
      </div>
    </ConfirmModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from './ConfirmModal';
import FormGroup from './FormGroup';
import FileUpload from './FileUpload';
import MessagesInbox from './MessagesInbox';
import Spinner from './Spinner';

const defaultNewMessage = {
  title: '',
  content: '',
  type: 'general',
  category: '',
  accountIds: []
};

export default {
  name: 'Messages',
  components: {
    ConfirmModal,
    FileUpload,
    FormGroup,
    MessagesInbox,
    Spinner
  },
  props: {
    parentType: {
      type: String,
      required: true
    },
    parentId: {
      type: String,
      required: true
    },
    parent: {
      type: Object,
      required: true
    },
    assetId: {
      type: String,
      required: false,
      default: null
    },
    entityId: {
      type: String,
      required: true
    },
    accounts: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      modals: {
        remove: false,
        edit: false
      },
      newMessage: { ...defaultNewMessage },
      newAttachments: null,
      showNewMessage: false,
      search: '',
      reassignUser: false,
      selectedMessageId: '',
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      messages: 'message/messages',
      uploadStatus: 'message/uploadStatus',
      loadingAction: 'message/loadingAction',
      users: 'company/users',
      company: 'user/company',
      validationErrors: 'message/validationErrors'
    }),
    accountOptions() {
      return this.accounts.map(acc => ({ label: `${acc.type} - ${acc.name} [${acc.meterPointNumber}]`, value: acc._id }));
    },
    pinnedReplies() {
      return this.selectedMessage.replies.filter(m => m.pinned && !m.deletedOn);
    },
    selectedMessage() {
      if (!this.selectedMessageId) return {};

      const selectedMessage = this.messages.find(message => message._id === this.selectedMessageId);

      return selectedMessage || {};
    },
    filteredMessages() {
      if (this.messages.length === 0) return [];

      const messages = [...this.messages];

      messages.sort((a, b) => {
        const aLastReplyDate = a.replies[a.replies.length - 1].createdAt;
        const bLastReplyDate = b.replies[b.replies.length - 1].createdAt;

        return new Date(bLastReplyDate) - new Date(aLastReplyDate);
      });

      const filteredBySearch = messages.filter(
        message =>
          !this.search ||
          message.title.includes(this.search.toLowerCase()) ||
          message.replies
            .map(r => r.content)
            .join(' ')
            .includes(this.search.toLowerCase())
      );

      const sortByPinned = filteredBySearch.reduce((acc, value) => {
        if (value.pinned) return [value, ...acc];
        return [...acc, value];
      }, []);

      return [...sortByPinned.filter(m => m.status === 'open'), ...sortByPinned.filter(m => m.status !== 'open')];
    },
    companyCategories() {
      return this.company.settings && this.company.settings.messageCategories
        ? this.company.settings.messageCategories.map(category => ({ label: category, value: category }))
        : [];
    },
    messageTypes() {
      return ['general', 'information', 'action', 'other'].map(t => ({ label: `${t.charAt(0).toUpperCase()}${t.slice(1)}`, value: t }));
    },
    companyUsers() {
      const users = [...this.users.map(user => ({ label: `${user.name} [${user.email}]`, value: user.userSub }))];

      users.sort((a, b) => a.label.localeCompare(b.label));

      return users;
    }
  },
  mounted() {
    this.listUsers({ id: this.$auth.companyId });
    this.getCompany();
    this.refresh(this.$route.params.messageId);
  },
  methods: {
    ...mapActions({
      listMessages: 'message/list',
      createMessage: 'message/create',
      deleteMessage: 'message/remove',
      updateMessage: 'message/update',
      addReply: 'message/addReply',
      updateReply: 'message/updateReply',
      deleteReply: 'message/deleteReply',
      downloadAttachment: 'message/downloadAttachment',
      uploadAttachment: 'message/uploadAttachment',
      getLinkedAccounts: 'message/getLinkedAccounts',
      listUsers: 'company/listUsers',
      getCompany: 'user/getCompany',
      assignUser: 'message/assignUser'
    }),
    async refresh(id) {
      this.loading = true;

      await this.listMessages({ data: { params: { parentType: this.parentType, parentId: this.parentId } } });

      let selectedMessage;

      if (id) {
        selectedMessage = this.messages.find(message => message._id === id);
      } else if (this.selectedMessage._id) {
        selectedMessage = this.messages.find(message => message._id === this.selectedMessage._id);
      } else if (this.messages.length > 0) {
        selectedMessage = this.messages[0];
      }

      if (selectedMessage) this.onClickMessage(selectedMessage);

      this.loading = false;
    },
    getUserFromSub(sub) {
      const user = this.users.find(user => user.userSub === sub);

      if (user) {
        return user;
      }

      return {
        name: 'N/A',
        email: 'N/A',
        picture: ''
      };
    },
    onClickMessage(message) {
      this.selectedMessageId = message._id;
      this.showNewMessage = false;

      const linkedAccount = message.linkedIds.find(linkedId => linkedId.type === 'account');
      const linkedAccountIds = linkedAccount ? linkedAccount.objectIds : [];

      if (linkedAccountIds.length > 0) this.getLinkedAccounts({ id: message._id, accountIds: linkedAccountIds });

      this.$mixpanel.track('Message View', {
        'Message ID': message._id,
        'Message Title': message.title,
        'Asset ID': this.assetId
      });
    },
    resetState() {
      this.newMessage = { ...defaultNewMessage };
      this.newAttachments = null;
    },
    async handleAttachments() {
      const formData = this.newAttachments?.files;
      if (formData) {
        const uploadedAttachments = await this.uploadAttachment({ data: formData });
        if (uploadedAttachments && uploadedAttachments.length > 0) return uploadedAttachments;
        this.$toasted.error('There has been an error uploading the attachments');
      }
      return [];
    },
    async onClickCreate() {
      const attachments = await this.handleAttachments();

      let linkedIds = {};

      if (this.newMessage.accountIds) {
        linkedIds = {
          objectIds: this.newMessage.accountIds,
          type: 'account'
        };
      }

      const newMessage = await this.createMessage({
        data: {
          title: this.newMessage.title,
          type: this.newMessage.type,
          category: this.newMessage.category,
          dueDate: this.newMessage.dueDate,
          parentType: this.parentType,
          parentId: this.parentId,
          assetId: this.assetId,
          entityId: this.entityId,
          companyId: this.$auth.companyId,
          linkedIds,
          replies: [
            {
              content: this.newMessage.content,
              attachments,
              userSub: this.$auth.user.sub
            }
          ]
        }
      });

      if (newMessage) {
        this.$toasted.success('New message posted successfully.');

        this.refresh(newMessage._id);

        this.showNewMessage = false;
      }

      this.$mixpanel.track('Message Create', {
        'Message ID': newMessage._id,
        'Message Title': newMessage.title,
        'Message Type': newMessage.type,
        'Message Category': newMessage.category,
        'Message Attachments': newMessage.replies[0].attachments.length,
        'Asset ID': this.assetId
      });

      this.resetState();
    },
    async onClickReply() {
      const attachments = await this.handleAttachments();

      const noOfOldReplies = this.selectedMessage.replies.length;

      const updatedReplies = await this.addReply({
        id: this.selectedMessage._id,
        data: {
          content: this.newMessage.content,
          attachments
        }
      });

      if (updatedReplies.length > noOfOldReplies) {
        this.$toasted.success('New reply posted successfully.');

        this.refresh();

        this.showNewMessage = false;
      } else {
        this.$toasted.error('Reply could not be sent at this time.');
      }

      this.$mixpanel.track('Message Reply Created', {
        'Message ID': this.selectedMessage._id,
        'Asset ID': this.assetId,
        'Message Replies': updatedReplies.length,
        'Message Attachments Added': attachments.length
      });

      this.resetState();
    },
    async onClickDownload(reply, attachment) {
      try {
        const downloadUrl = await this.downloadAttachment({
          id: this.selectedMessage._id,
          replyId: reply._id,
          attachmentId: attachment._id,
          name: attachment.name
        });

        const link = document.createElement('a');
        link.target = '_blank';
        link.href = downloadUrl;
        link.download = attachment.name;
        link.click();
        URL.revokeObjectURL(link.href);

        this.$mixpanel.track('Message Attachment Download', {
          'Message ID': this.selectedMessage._id,
          'Asset ID': this.assetId,
          'Attachment ID': attachment._id,
          'Attachment Name': attachment.name
        });
      } catch (e) {
        this.$toasted.error(e.errorMsg || 'Could not download file', { position: 'bottom-center', duration: 3000 });
      }
    },
    onClickDeleteMessage(message) {
      this.modals.remove = message;
    },
    async onRemove() {
      try {
        const deleteMessage = await this.deleteMessage({ id: this.modals.remove._id });

        if (deleteMessage) {
          this.$toasted.success('Deleted successfully.');
        }
      } catch (e) {
        this.$toasted.error('Could not delete.');
      }

      this.modals.remove = false;
      this.refresh();

      this.$mixpanel.track('Message Removed', {
        'Message ID': this.selectedMessage._id,
        'Asset ID': this.assetId
      });
    },
    onClickEditMessage() {
      const linkedAccount = this.selectedMessage.linkedIds.find(linkedId => linkedId.type === 'account');
      const linkedAccountIds = linkedAccount ? linkedAccount.objectIds : [];

      this.modals.edit = {
        title: this.selectedMessage.title,
        content: this.selectedMessage.content,
        type: this.selectedMessage.type,
        category: this.selectedMessage.category,
        dueDate: this.selectedMessage.dueDate,
        assignedUserSub: this.selectedMessage.assignedUserSub,
        accountIds: linkedAccountIds
      };
    },
    async onEdit() {
      try {
        if (this.modals.edit.accountIds) {
          this.modals.edit.linkedIds = {
            objectIds: this.modals.edit.accountIds,
            type: 'account'
          };

          delete this.modals.edit.accountIds;
        }

        const data = {
          ...this.modals.edit
        };

        if (data.type !== 'action') {
          data.dueDate = null;
        }

        const updatedMessage = await this.updateMessage({ id: this.selectedMessage._id, data });

        if (updatedMessage) {
          this.$toasted.success('Updated successfully.');
        }

        this.$mixpanel.track('Message Edited', {
          'Message ID': this.selectedMessage._id,
          'Asset ID': this.assetId
        });
      } catch (e) {
        this.$toasted.error('Could not update.');
      }

      this.modals.edit = false;
      this.refresh();
    },
    onFileSelect(e) {
      const { name, files } = e.target;

      const formData = new FormData();

      Array.from(Array(files.length).keys()).forEach(x => {
        formData.append(name, files[x], files[x].name);
      });

      this.newAttachments = formData;
    },
    async onClickCloseMessage(message) {
      try {
        const updatedMessage = await this.updateMessage({ id: message._id, data: { status: message.status === 'open' ? 'closed' : 'open' } });

        if (updatedMessage) {
          this.$toasted.success('Updated successfully.');
        }
      } catch (e) {
        this.$toasted.error('Could not update message.');
      }

      this.refresh();
    },
    async onClickDeleteReply(reply) {
      this.$set(reply, 'isDeleting', true);

      try {
        const deletedReply = await this.updateReply({
          id: this.selectedMessage._id,
          replyId: reply._id,
          data: {
            ...reply,
            deletedOn: new Date()
          }
        });

        if (deletedReply._id === reply._id) {
          this.$toasted.success('Reply deleted successfully.');
        }
      } catch (e) {
        this.$toasted.error('Could not delete reply.');
      }

      this.$set(reply, 'isDeleting', false);

      this.refresh();

      this.$mixpanel.track('Message Reply Removed', {
        'Message ID': this.selectedMessage._id,
        'Reply ID': reply._id,
        'Asset ID': this.assetId
      });
    },
    onClickEditReply(reply, type) {
      this.$set(reply, type, true);
    },
    async onClickPinMessage() {
      await this.updateMessage({
        id: this.selectedMessage._id,
        data: {
          ...this.selectedMessage,
          pinned: this.selectedMessage.pinned ? !this.selectedMessage.pinned : true
        }
      });

      this.refresh();

      this.$mixpanel.track('Message Pin', {
        'Message ID': this.selectedMessage._id,
        'Asset ID': this.assetId
      });
    },
    async onClickPinReply(reply) {
      await this.updateReply({
        id: this.selectedMessage._id,
        replyId: reply._id,
        data: {
          ...reply,
          pinned: reply.pinned ? !reply.pinned : true
        }
      });

      this.refresh();
    },
    async onClickSaveReply(reply, type) {
      this.$set(reply, 'isSaving', true);
      await this.onEditReply(reply);
      this.$set(reply, 'isSaving', false);

      this.$set(reply, type, false);
    },
    async onChangeAssignedUser(value) {
      try {
        const updatedMessage = await this.assignUser({
          id: this.selectedMessage._id,
          userSub: value
        });

        if (updatedMessage.assignedUserSub === value) {
          this.$toasted.success('Assigned user successfully.');
        }

        this.$mixpanel.track('Message Reassign User', {
          'Message ID': this.selectedMessage._id,
          'Assigned User': updatedMessage.assignedUserSub,
          'Asset ID': this.assetId
        });
      } catch (e) {
        this.$toasted.error('Could not update message category.');
      }

      this.reassignUser = false;
    },
    async onEditReply(reply) {
      try {
        const editedReply = await this.updateReply({
          id: this.selectedMessage._id,
          replyId: reply._id,
          data: reply
        });

        if (editedReply._id === reply._id) {
          this.$toasted.success('Reply edited successfully.');

          this.$mixpanel.track('Message Reply Edited', {
            'Message ID': this.selectedMessage._id,
            'Reply ID': reply._id,
            'Asset ID': this.assetId
          });
        }
      } catch (e) {
        this.$toasted.error('Could not edit reply');
      }
    }
  }
};
</script>
<style lang="scss">
.message.list-group-item.message-active {
  background: #e4e9f3;
}

.message-content {
  white-space: pre-wrap;
}

.account-list {
  overflow-y: scroll;
  max-height: 15vh;
}
.account-list::-webkit-scrollbar {
  -webkit-appearance: none;
}
.account-list::-webkit-scrollbar:vertical {
  width: 11px;
}
.account-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
