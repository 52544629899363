import mixpanel from 'mixpanel-browser';

export default {
  install(Vue, options) {
    mixpanel.init(options.token, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage'
    });

    Vue.mixpanel = mixpanel;
    Vue.prototype.$mixpanel = mixpanel;
  }
};
